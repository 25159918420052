import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Link} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from 'clsx'
import {getTags} from "../../query/tags";
import { getCounterActiveOrders } from "../../store/actions/cart";
import { userInfoSelector } from "../../store/selectors/user";
import { activeOrderCountSelector } from "../../store/selectors/cart";
import Menu from "../../components/menu";
import {defaultAddress, getAddress} from "../../query/address";
import ModalLogout from "../../components/modal-logout";
import TopPage from "../../components/top-page";
import { SLUGS_TEXT_PAGES } from "../../constants/text-pages";
import IconEdit from '../../img/icons/icon-edit.svg';
import './lk.scss'

const getSelectedPreferences = (list = [], userTags = []) => (
    list.filter(({ tagId }) => userTags.some((val) => val === tagId))
)

const Lk = () => {
    const dispatch = useDispatch()
    const userData = useSelector(userInfoSelector)
    const activeOrderCount = useSelector(activeOrderCountSelector)
    const [address, setAddress] = useState(defaultAddress);
    const [preferences, setPreferences] = useState([]);
    const [showModalLogout, setShowModalLogout] = useState(false);

    useEffect(()=>{
        // getTags((res) => {
        //     if (res.userTags.length === 0) {
        //         setPreferences([])
        //         return
        //     }

        //     const list = [
        //         ...getSelectedPreferences(res.preferences, res.userTags),
        //         ...getSelectedPreferences(res.exclude, res.userTags),
        //     ]

        //     setPreferences(list)
        // }, () => {}, { useCache: true })
        dispatch(getCounterActiveOrders())
        getAddress(setAddress);
    }, []);

    const renderAddress = useMemo(() => {
        if (!address.default && address.street) {
            const list = [address.street, address.house, address.apartment ? 'кв. ' + address.apartment : null].filter((v) => v)
            return list.join(', ')
        }
        return 'Адрес неизвестен'
    })

    const onShowModalLogout = useCallback(() => setShowModalLogout(true), [])
    const onHideModalLogout = useCallback(() => setShowModalLogout(false), [])

    const renderPhone = useMemo(() => {
        const phone = userData.telephone

        return `+7 ${phone.slice(1, 4)} ${phone.slice(4, 7)} ${phone.slice(7, 9)} ${phone.slice(9, 11)}`
    }, [userData.telephone])

    return (
        <div className="lk-page">
            <TopPage
                to="/Catalog"
                title="Профиль"
            />
            <div className="grid-inner">
                <div className={clsx('lk-page__block', 'lk-page__info')}>
                    <Link to="/LkRedact" className="lk-page__btn-edit">
                        <IconEdit />
                    </Link>
                    <div className="lk-page__icon">😊</div>
                    <p className="lk-page__name text">{userData.firstname}</p>
                    <p className="lk-page__phone text">{renderPhone}</p>
                    <p className="lk-page__address text">{renderAddress}</p>
                </div>
                <div className="lk-page__block lk-page__menu">
                    <Menu preferences={preferences} onShowModalLogout={onShowModalLogout} activeOrderCount={activeOrderCount}/>
                </div>
            </div>
            <div className="lk-page__bottom">
                <div className="grid-inner">
                    <div className="lk-page__link">
                      <Link to={`/pages/${SLUGS_TEXT_PAGES.PROMO}`} className="lk-page__bottom-link">Акция</Link>
                    </div>
                    <div className="lk-page__link">
                      <Link to={`/pages/${SLUGS_TEXT_PAGES.OFFER}`} className="lk-page__bottom-link">Оферта</Link>
                    </div>
                    <div className="lk-page__link">
                      <Link to={`/pages/${SLUGS_TEXT_PAGES.SECURITY_POLICY}`} className="lk-page__bottom-link">Правила обработки персональных данных</Link>
                    </div>
                </div>
            </div>
            <ModalLogout visible={showModalLogout} onClose={onHideModalLogout} />
        </div>
    )
}

export default Lk
