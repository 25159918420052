import React, {useCallback, useEffect, useState, useRef} from 'react';
import {YMaps, Map, GeoObject, GeolocationControl, ZoomControl, Polygon} from "react-yandex-maps";
import {Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { isAuthSelector } from "../../store/selectors/user";
import API from "../../api";
import {defaultAddress, getAddress, saveAddress, clearAddressForNewTempUser} from "../../query/address";
import { QUERY_MAP } from "../../constants/map";
import { bindAddress, validate } from "../../util/address";
import ModalDeliveryAddress from "../../components/modal-delivery-address";
import { useReducer } from "../../util/store";
import IconPrev from '../../img/icons/prev-arrow.svg'
import polygonData, { options as optionsPolygon } from '../../constants/polygon'
import { checkPointInPolygons } from '../../util/map'
import './map.scss'

const polygons = [{
    id: 'main',
    coordinates: polygonData
}]

let ymaps = null;
let maps = null;

const opt = {
    setAddress: null,
    move: false,
    onMove: (map) => {
        if (opt.move) {
            opt.move = false;
            let coords = maps.getCenter();
            ymaps.geocode(coords).then(function(res) {
                let geoObject = res.geoObjects.get(0);
                if(validate(geoObject)){
                    let address = bindAddress(geoObject);
                    opt.setAddress(address);
                }else{
                    opt.setAddress(defaultAddress);
                }
            });
        }
    },
    onDrag: () => {
        opt.move = true;
    }
}

const Maps = (props) => {
    const [alert, setAlert] = useReducer('alert')
    const fromMain = props.location?.state?.fromMain
    const fromParentLocation = props.location?.state?.from
    const isAuth = useSelector(isAuthSelector)
    const history = useHistory();
    const [address, setAddress] = useState(defaultAddress);
    const [initialCoordinates, setInitialCoordinates] = useState([
        defaultAddress.coordinates.lat, defaultAddress.coordinates.lon
    ]);
    const [coordinates, setCoordinates] = useState([
        defaultAddress.coordinates.lat, defaultAddress.coordinates.lon
    ]);
    opt.setAddress = setAddress;

    const [modalDeliveryAddress, setModalDeliveryAddress] = useState(false)
    const onCloseModalDeliveryAddress = useCallback(() => setModalDeliveryAddress(false), [])

    useEffect(()=>{
        if(isAuth) {
            getAddress((address) => {
                if (address.street) {
                    setAddress(address);
                    setCoordinates([
                        address.coordinates.lat, address.coordinates.lon
                    ]);
                    setInitialCoordinates([
                        address.coordinates.lat, address.coordinates.lon
                    ]);
                }
            });
        }else{
            // Очищаем адрес перед созданием нового временного юзера
            clearAddressForNewTempUser()
            // TODO: temp hide
            // API.temp()
        }
    }, [isAuth]);

    const polygonsRef = useRef([])

    const initMap = map => {
        if(map) {
            maps = map;
            maps.events.add("actionend", opt.onMove);
            maps.behaviors.events.add('dragend', opt.onDrag);
        }
    };

    const onLoadMap = ym => {
        ymaps = ym;
        ymaps.geolocation.get({
            provider: 'auto',
            mapStateAutoApply: true
        }).then(function(result) {
            var geoObject = result.geoObjects.get(0);
            if(validate(geoObject)){
                setAddress(bindAddress(geoObject));
                setCoordinates(geoObject.geometry.getCoordinates());
            }
        });

        if(address.default){
            ymaps.geocode(maps.getCenter()).then(function(res) {
                let geoObject = res.geoObjects.get(0);
                if(validate(geoObject)){
                    setAddress(bindAddress(geoObject));
                }
            });
        }
    }

    const setInstanceRef = useCallback((index, ref) => {
        polygonsRef.current[index] = ref
    }, [])

    const onSave = useCallback((e) => {
        e.preventDefault();
        // Проверяем точку
        if (!address.street || !address.house) {
            setAlert({ danger: 'Поле дом обязательно при обновлении координаты' })
            return
        }

        // Проверяем вхождение в зону доставки
        const isContains = checkPointInPolygons(ymaps, maps, polygonsRef.current, [address.coordinates.lat, address.coordinates.lon])

        if (!isContains) {
            setAlert({ danger: 'Выбранный адрес не входит в зону доставки' })
            return
        }


        // Для анонимных пользователей сразу показываем модалку, т.к. адрес негде сохранить
        if (!isAuth) {
            setModalDeliveryAddress(true)
            return
        }

        // Для авторизованных юзеров
        if (initialCoordinates.toString() === [address.coordinates.lat, address.coordinates.lon].toString()) {
            saveAddress(address, (res) => {
                setInitialCoordinates([
                    res.coordinates.lat, res.coordinates.lon
                ]);
                setModalDeliveryAddress(true)
            });
        } else {
            // Если координаты изменились, очищаем остальные поля при сохранении нового адреса
            saveAddress({
                ...address,
                apartment: '',
                floor: '',
                entrance: '',
                code: '',
                comment: '',
            }, (res) => {
                setInitialCoordinates([
                    res.coordinates.lat, res.coordinates.lon
                ]);
                setModalDeliveryAddress(true)
            });
        }
    }, [address, initialCoordinates, isAuth])

    return (
        <div className="map-page">
            <div className="grid-inner map-page__wrap">
                <div className="grid-inner map-page__top">
                    <div onClick={history.goBack} className="map-page__back">
                        <IconPrev/>
                    </div>
                </div>
                <div className="map">
                    <div className="marker-map">
                        <svg width="29" height="44" viewBox="0 0 29 44" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <line x1="14" y1="42.687" x2="14" y2="20.5203" stroke="#FF007A"
                                  strokeWidth="2" strokeLinecap="round"/>
                            <rect y="0.187012" width="29" height="29" rx="14.5" fill="#FF007A"/>
                        </svg>
                    </div>

                    <YMaps query={QUERY_MAP}>
                        <Map
                            onLoad={onLoadMap}
                            instanceRef={initMap}
                            width="100%" height="100%"
                            state={{ center: coordinates, zoom: 17 }}
                            options={{ suppressMapOpenBlock: true }}
                        >
                            <GeolocationControl options={{position: {right: 20, top: 20}}} />
                            <ZoomControl options={{position: {right: 20, top: 80}}} />
                            <GeoObject />
                            {polygons.map(({ id, coordinates }, index) => (
                                <Polygon
                                    instanceRef={(value) => setInstanceRef(index, value)}
                                    key={id}
                                    geometry={coordinates}
                                    options={optionsPolygon}
                                />
                            ))}
                        </Map>
                    </YMaps>
                </div>

                <div className="map-page__bottom">
                    <Link
                        to={{
                            pathname: '/Address',
                            state: { fromMain, from: fromParentLocation }
                        }}
                        className="h1 map-text"
                    >
                        {address.street}{address.house ? ', ' + address.house : ''}
                    </Link>
                    <a onClick={onSave} className="btn btn-primary">Да, все верно</a>
                </div>
            </div>
            <ModalDeliveryAddress
                fromLocation="Map"
                fromParentLocation={fromParentLocation}
                fromMain={fromMain}
                visible={modalDeliveryAddress}
                onClose={onCloseModalDeliveryAddress}
                baseAddress={address}
                isAnonymous={!isAuth}
            />
        </div>
    );
};


export default Maps;
