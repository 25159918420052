import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import clsx from 'clsx'
import { getInfoCart, setInfoCart, clearCart, deleteAllProducts } from '../../store/actions/cart'
import { clearOrder } from '../../store/actions/order'
import { cartInfoSelector, cartInfoStatusSelector } from '../../store/selectors/cart'
import { orderCommentSelector, cutlerySelector } from '../../store/selectors/order'
import { userInfoSelector } from '../../store/selectors/user'
import CartProduct from '../../components/cart-product'
import Cutlery from '../../components/cutlery'
import TopPage from '../../components/top-page'
import CartLink from '../../components/cart-link'
import Switch from '../../components/switch'
import PromoCode from '../../components/promo-code'
import CartPayment from '../../components/cart-payment'
import CartTime from '../../components/cart-time'
import EmptyBlock from '../../components/empty-block'
import ModalComment from '../../components/modal-comment'
import ModalEmail from '../../components/modal-email'
import { defaultAddress, getAddress } from '../../query/address'
import API from '../../api'
import { getFormattedPrice } from '../../util/number'
import IconTrash from '../../img/icons/cart/trash.svg'
import useLayout from '../../util/layout'
import { CHANGE_LAYOUT } from '../../constants/events'
import { SHIPPING_CODES, getTimeNowByShippingCode } from '../../constants/shipping'
import './styles.scss'

const defaultAddressPoint = 'пр. 1-й Дорожный, д. 1 к. 2'
const defaultPaymentCodeSber = 'sber'

const Block = ({ children, title = '', mod = '' }) => (
  <div className="grid-inner">
    <div className={clsx('cart-block', mod)}>
      <p className="cart-block__title">{title}</p>
      <div className="cart-block__body">{children}</div>
    </div>
  </div>
)

const triggerEventChangeLayout = () => {
  const event = new CustomEvent(CHANGE_LAYOUT)
  window.dispatchEvent(event);
}

const TIME_REPEAT_CHECK_STATUS_CART = 1000 * 60 * 1

const Cart = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const cartData = useSelector(cartInfoSelector)
  const cartStatus = useSelector(cartInfoStatusSelector)
  const orderComment = useSelector(orderCommentSelector)
  const cutleryCount = useSelector(cutlerySelector)
  const userData = useSelector(userInfoSelector)
  const [address, setAddress] = useState(defaultAddress)

  const [visibleModalComment, setVisibleModalComment] = useState(false)
  const [visibleModalEmail, setVisibleModalEmail] = useState(false)
  const [visibleModalPay, setVisibleModalPay] = useState(false)
  const [visibleModalCode, setVisibleModalCode] = useState(false)
  const [visibleModalTime, setVisibleModalTime] = useState(false)
  const modalHandlers = useMemo(() => ({
    comment: { onHide: () => setVisibleModalComment(false), onShow: () => setVisibleModalComment(true) },
    email: { onHide: () => setVisibleModalEmail(false), onShow: () => setVisibleModalEmail(true) },
    pay: { onHide: () => setVisibleModalPay(false), onShow: () => setVisibleModalPay(true) },
    code: { onHide: () => setVisibleModalCode(false), onShow: () => setVisibleModalCode(true) },
    time: { onHide: () => setVisibleModalTime(false), onShow: () => setVisibleModalTime(true) },
  }), [])
  const firstLoadingPage = useRef(false)

  const setCartData = useCallback((data, isUnwrap = false) => {
    if (isUnwrap) {
      return dispatch(setInfoCart(data)).unwrap()
    }
    return dispatch(setInfoCart(data))
  }, [dispatch])

  useEffect(() => {
    const getAddressByPromise = () => new Promise((resolve, reject) => {
      getAddress(resolve, reject)
    })

    const func = async () => {
      try {
        const promises = [
          getAddressByPromise(),
          dispatch(getInfoCart()),
        ]

        const results = await Promise.allSettled(promises)
        if (!(results[0].status === 'fulfilled' && results[1].status === 'fulfilled')) {
          throw new Error('Error')
        }

        const addressRes = results[0].value
        setAddress(addressRes)
        triggerEventChangeLayout()
      } catch (error) {
        console.log(error)
      }
    }

    func()
    const timer = setInterval(async () => {
      try {
        dispatch(getInfoCart())
        triggerEventChangeLayout()
      } catch (error) {
        console.log(error)
      }
    }, TIME_REPEAT_CHECK_STATUS_CART)

    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    const event = new CustomEvent(CHANGE_LAYOUT)
    window.dispatchEvent(event);
  }, [cartData.products.length])

  useLayout(cartData.products.length)

  useEffect(() => {
    if (cartStatus !== 'success' || firstLoadingPage.current ) {
        return
    }

    // Данное действие выполняется 1 раз, чтобы не уйти в цикл
    // TODO: временно насильно меняем тип доставки на доставку курьером
    // также устанавливаем дефолтное время и тип оплаты - Онлайн оплата
    if (cartData.shipping?.code !== SHIPPING_CODES.FLAT) {
      const newShippingCode = SHIPPING_CODES.FLAT
      const newTime = getTimeNowByShippingCode(cartData.time, newShippingCode)
      dispatch(setInfoCart({ shipping: newShippingCode, time: newTime, payment: defaultPaymentCodeSber }))
      firstLoadingPage.current = true
      return
    }

    // Получаем время, на тот случай если оно пусто
    const newShippingCode = SHIPPING_CODES.FLAT
    const newTime = getTimeNowByShippingCode(cartData.time, newShippingCode)

    // TODO: временно насильно меняем тип оплаты на Онлайн оплата и выставляем время
    if (cartData.payment?.code !== defaultPaymentCodeSber) {
      dispatch(setInfoCart({ payment: defaultPaymentCodeSber, time: newTime }))
      firstLoadingPage.current = true
      return
    }

    // Если время пустое - устанавливаем дефолтное
    if (!cartData.time) {
      dispatch(setInfoCart({ time: newTime }))
      firstLoadingPage.current = true
    }

    firstLoadingPage.current = true
  }, [cartData, firstLoadingPage])

  const onChangeShipping = useCallback((checked) => {
    if (address.street && address.house) {
      const newShippingCode = checked ? SHIPPING_CODES.PICKUP : SHIPPING_CODES.FLAT
      const newTime = getTimeNowByShippingCode(cartData.time, newShippingCode)
      dispatch(setInfoCart({ shipping: newShippingCode, time: newTime }))
      return
    }

    history.push('/Map', { from: location.pathname })
    return
  }, [dispatch, address.street, address.house, address.apartment, location.pathname, cartData.time])

  const createOrder = useCallback(() => {
    if (!address.street) {
      history.push('/Map', { from: location.pathname })
      return
    }

    if(!userData.email){
      return modalHandlers.email.onShow();
    }

    if(!cartData.payment){
      return modalHandlers.pay.onShow();
    }

    if(!cartData.time){
      return modalHandlers.time.onShow();
    }

    const comment = `${orderComment}\n Кол-во приборов: ${cutleryCount}.`

    API.post(
      '/order',
      {create: true, comment, email: userData.email},
      function (response){
        if (response.url) {
        //   dispatch(clearOrder())
          window.location.href = response.url
          return
        }
        if (!response.exception) {
        //   dispatch(clearOrder())
          history.push('/OrderAccepted')
          return
        }
      },
      function() {
        console.log('FAIL')
      }
    );
  }, [cartData, dispatch, dispatch, history, address, orderComment, cutleryCount, modalHandlers, userData.email])

  const renderAddress = useMemo(() => {
    if (address.street) {
      const list = [address.street, address.house, address.apartment ? 'кв. ' + address.apartment : null].filter((v) => v)
      return list.join(', ')
    }

    return 'Адрес неизвестен'
  }, [address])

  const onDeleteAllProducts = useCallback(async () => {
    await dispatch(deleteAllProducts())
    dispatch(clearCart())
  }, [dispatch])
  const isShippingPickup = useMemo(() => cartData.shipping?.code === SHIPPING_CODES.PICKUP, [cartData.shipping?.code])
  const isShippingFlat = useMemo(() => cartData.shipping?.code === SHIPPING_CODES.FLAT, [cartData.shipping?.code])

  const discount = useMemo(() => {
    const discountPrice = cartData?.promocode?.price
    if (!discountPrice || !cartData.total) {
      return 0
    }

    return cartData.total - discountPrice
  }, [cartData?.promocode?.price, cartData.total])


  if (!cartData.products.length) {
    return (
      <div className="layout cart">
        <div className="layout__top layout__top--gray">
          <TopPage
            onClick={history.goBack}
            title="Заказ"
          />
        </div>
        <div className="layout__body cart__body">
          <EmptyBlock showBtn>
            <p className="bold">В корзине пока пусто</p>
            <p>Для того чтобы оформить заказ перейдите в каталог и добавьте товары в корзину</p>
          </EmptyBlock>
        </div>
      </div>
    )
  }

  return (
    <div className="layout cart">
      <div className="layout__top layout__top--gray">
        <TopPage
          onClick={history.goBack}
          title="Заказ"
        />
      </div>
      <div className="layout__body cart__body">
        <Block title="Адрес" mod="cart__address">
          <CartLink
            text={isShippingPickup ? defaultAddressPoint : renderAddress}
            note={isShippingPickup ? 'Приготовим за 45 минут' : 'Доставим через 45 минут'}
            icon="location"
            to={{
              pathname: '/Map',
              state: { from: location.pathname }
            }}
            mod="cart__link"
            disabled={isShippingPickup}
          />
          {/* <div className="cart__switch">
            <Switch
              label="Самовывоз"
              checked={isShippingPickup}
              onChange={onChangeShipping}
            />
          </div> */}
          <CartLink
            text="Электронная почта"
            note={userData.email || 'Введите email для отправки чека'}
            icon="email"
            onClick={modalHandlers.email.onShow}
            mod="cart__link cart__link--border-top nowrap"
          />
          <CartLink
            text="Пожелания к заказу"
            note={orderComment.trim().length ? orderComment : 'Добавьте комментарий'}
            icon="message"
            onClick={modalHandlers.comment.onShow}
            mod="cart__link cart__link--border-top nowrap"
          />
          <CartLink
            text="Время"
            note={cartData.time || (isShippingFlat ? 'Выберите время доставки' : 'Выберите время самовывоза')}
            icon="time"
            onClick={modalHandlers.time.onShow}
            mod="cart__link cart__link--border-top"
          />
        </Block>
        <Block title="Заказ" mod="cart__products">
          <div onClick={onDeleteAllProducts} className="cart__trash"><IconTrash /></div>
          <div className="cart-list">
            {cartData.products.map((product, index) => (
              <CartProduct key={`${product.cartId}_${index}`} data={product} cartData={cartData} setCartData={setCartData}/>
            ))}
          </div>
          <Cutlery />
        </Block>
        <Block title="Оплата" mod="cart__payment">
          <CartLink
            text={cartData?.payment?.name || 'Выберете тип оплаты'}
            image={cartData?.payment?.image || ''}
            icon="money"
            onClick={modalHandlers.pay.onShow}
            mod="cart__small-link"
            disabled
          />
          <CartLink
            text={cartData?.promocode?.code || 'Промокод'}
            icon={cartData?.promocode?.code ? 'coupon-active' : 'coupon'}
            onClick={modalHandlers.code.onShow}
            mod="cart__small-link"
          />
        </Block>
        <Block title="Чек">
          <div className="cart-total__block">
            <p className="cart-total__label">Сумма заказа</p>
            <p className="cart-total__value">{getFormattedPrice(cartData.subTotal)} ₽</p>
          </div>
          {isShippingFlat ? (
            <div className="cart-total__block">
              <p className="cart-total__label">Доставка</p>
              <p className="cart-total__value">{getFormattedPrice(cartData?.shipping?.price || 0)} ₽</p>
            </div>
          ) : null}
          <div className="cart-total__block">
            <p className="cart-total__label _bold">Итого</p>
            <p className="cart-total__value">
              {discount ? (
                <span className="cart-total__old-price">{getFormattedPrice(discount)} ₽</span>
              ) : null}
              <span>{getFormattedPrice(cartData.total)} ₽</span>
            </p>
          </div>
        </Block>
      </div>
      <div className="layout__bottom">
        <div className="grid-inner">
          <Button onClick={createOrder} className="cart-btn" variant="primary" disabled={cartData.serviceError}>Оформить заказ</Button>
          {cartData.serviceError ? (
            <p className="cart__bottom-note">{cartData.serviceError}</p>
          ) : null}
        </div>
      </div>
      <ModalComment
        visible={visibleModalComment}
        onClose={modalHandlers.comment.onHide}
        orderComment={orderComment}
      />
      <ModalEmail
        visible={visibleModalEmail}
        onClose={modalHandlers.email.onHide}
        value={userData.email || ''}
      />
      <CartPayment
        visible={visibleModalPay}
        onClose={modalHandlers.pay.onHide}
        setCartData={setCartData}
        payment={cartData.payment}
      />
      <PromoCode
        setCartData={setCartData}
        visible={visibleModalCode}
        onClose={modalHandlers.code.onHide}
      />
      <CartTime
        visible={visibleModalTime}
        onClose={modalHandlers.time.onHide}
        setCartData={setCartData}
        time={cartData.time}
        title={isShippingPickup ? 'Время готовности заказа' : 'Время доставки'}
        shipping={cartData.shipping}
      />
    </div>
  )
}

export default Cart
