import { useReducer } from "../util/store";
import { IS_DEV } from "../constants/mode";
import { resetAddress } from "../query/address";

const USE_CACHE = (typeof USE_FRONTEND_CACHE !== 'undefined' && USE_FRONTEND_CACHE === 'true')

const baseUrl = ( typeof API_URL !== 'undefined' ? API_URL : '' ) + '/api';
const [alert, setAlert] = useReducer( 'alert' );

class APICache {
    constructor() {
        this.cache = {}
        this.MAX_TIME_LIVE_CACHE = 1000 * 60 * 5
    }

    clear(params = {}) {
        console.log('CLEAR CACHE')
        const { url, group } = params
        if (url) {
            this.cache[url] = null
        }
        if (group) {
            this.cache[group] = null
        }
    }

    add(url, res, group) {
        const obj = {
            date: Date.now(),
            data: res,
        }

        if (group) {
            if (this.cache[group]) {
                this.cache[group][url] = obj
                return
            }

            this.cache[group] = { [url]: obj }
            return
        }

        this.cache[url] = obj
    }

    hasAndValid(url, group) {
        const obj = group ? this.cache?.[group]?.[url] : this.cache[url]
        if (obj) {
            const date = obj.date
            const now = Date.now()
            const diff = now - date
            return diff < this.MAX_TIME_LIVE_CACHE
        }

        return false
    }

    get(url, group) {
        if (this.hasAndValid(url, group)) {
            return group ? this.cache[group][url].data : this.cache[url].data
        }

        return null
    }

    destroy() {
        this.cache = {}
    }
}

class API {
    constructor() {
        this.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        this.params = {
            headers: this.headers,
            credentials: "include",
            mode: 'cors',
        }
        this.basic = null
        this.callbacks = {}
        if (USE_CACHE) {
            this.cache = new APICache()
        }
    }

    setCallbacks(callbacks) {
        this.callbacks = callbacks
    }

    // Вход по номеру и смс
    login(username, password, callback = () => {}) {
        this.basic = window.btoa(username.replace(/[^0-9]/gi,'') + ':' + password);
        this.headers.Authorization = 'Basic ' + this.basic;
        if (this.callbacks.login) {
            this.callbacks.login(this.basic)
            callback()
        }
    }

    // Вход по токену после перезагрузки страницы.
    // Конечные токены по номеру телефону и коду и токены временного пользователя собираются немного по-разному
    // (см. методы login и temp).
    // По этой причине при старте приложение проще всего проверить наличие токена и использовать его,
    // а не генерировать новый из кредов, т.к. с api не приходит критерий, по которому можно будет понять временный юзер или нет.
    loginByToken(token) {
        this.basic = token;
        this.headers.Authorization = 'Basic ' + this.basic;
        if (this.callbacks.login) {
            this.callbacks.login(this.basic)
        }
    }

    logout() {
        this.basic = null
        this.headers.Authorization = ''
        if (USE_CACHE) {
            this.cache.destroy()
        }
        // Сбрасываем локальный адрес
        resetAddress()
        if (this.callbacks.logout) {
            this.callbacks.logout()
        }
    }

    // TODO: temp hide
    // Вход в качестве временного пользователя
    // temp() {
    //     this.post(
    //         '/user/temp',
    //         {},
    //         (data) => {
    //             this.basic = window.btoa(data.telephone + ':' + data.password);
    //             this.headers.Authorization = 'Basic ' + this.basic;
    //             if (this.callbacks.login) {
    //                 this.callbacks.temp(this.basic, data.telephone, data.password)
    //             }
    //         }
    //     )
    // }

    async get(url, callback, errorCallback, config = {}) {
        const isGuest = config?.isGuest || false
        const isHideAlert = config?.isHideAlert || false
        const useCache = config?.useCache || false
        const group = config?.group || false
        const replaceCacheURL = config?.replaceCacheURL || false
        const clearCache = config?.clearCache || false

        try {
            const cacheRes = USE_CACHE && useCache && this.cache.get(url, group)
            let result = null

            if (cacheRes) {
                result = cacheRes
            } else {
                const res = await fetch(baseUrl + url, {
                    ...this.params,
                    cache: 'no-cache',
                })
                result = await res.json()
            }

            if (result.message === 'Not authorized') {
                this.logout();
            } else if (IS_DEV && !isHideAlert && result.message) {
                setAlert({ danger: result.message });
            }
            if (callback) {
                if (USE_CACHE) {
                    if (useCache) {
                        this.cache.add(url, result, group)
                    }
                    if (replaceCacheURL) {
                        this.cache.add(replaceCacheURL, result)
                    }
                    if (clearCache) {
                        this.cache.clear(clearCache)
                    }
                }
                callback(result);
            }
        } catch (error) {
            if (errorCallback) {
                errorCallback(error);
            }
            if (IS_DEV && !isHideAlert && error.message) {
                setAlert(error.message);
            }
        }
    }

    async post(url, data, callback, errorCallback, config = {}) {
        const isGuest = config?.isGuest || false
        const isHideAlert = config?.isHideAlert || false
        const useCache = config?.useCache || false
        const replaceCacheURL = config?.replaceCacheURL || false
        const group = config?.group || false
        const clearCache = config?.clearCache || false

        try {
            const cacheRes = USE_CACHE && useCache && this.cache.get(url, group)
            let result = null
            if (cacheRes) {
                result = cacheRes
            } else {
                const res = await fetch(baseUrl + url, {
                    ...this.params,
                    method: 'POST',
                    body: JSON.stringify(data),
                    cache: 'no-cache',
                })
                result = await res.json()
            }

            if (result.message === 'Not authorized') {
                if (isGuest && errorCallback) {
                    errorCallback(result);
                    return
                }
                // TODO
                this.logout();
            } else if (IS_DEV && !isHideAlert && result.message) {
                setAlert({ danger: result.message });
            }
            if (callback) {
                if (USE_CACHE) {
                    if (useCache) {
                        this.cache.add(url, result, group)
                    }
                    if (replaceCacheURL) {
                        this.cache.add(replaceCacheURL, result)
                    }
                    if (clearCache) {
                        this.cache.clear(clearCache)
                    }
                }
                callback(result);
            }
        } catch (error) {
            if (errorCallback) {
                errorCallback(error);
            }
            if (IS_DEV && !isHideAlert && error.message) {
                setAlert(error.message);
            }
        }
    }
}

const api = new API()

export default api
