import React, { useCallback, useEffect, useState, useMemo } from 'react'
import {Button, Form, Row} from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import Modal from '../modal'
import AddressInput from "../AddressInput";
import Input from '../input/index'
import { defaultAddress, getAddress, saveAddress } from '../../query/address'
import './styles.scss'

const addressInp = [
  {id: 1, classNameCol: "col-4", placeholder: "Кв", name: "apartment"},
  {id: 2, classNameCol: "col-4", placeholder: "Этаж", name: "floor"},
  {id: 3, classNameCol: "col-4", placeholder: "Подъезд", name: "entrance"},
  {id: 4, classNameCol: "col-12", placeholder: "Код от домофона", name: "code"},
  {id: 5, classNameCol: "col-12", placeholder: "Комментарий", name: "comment", isTextarea: true },
]

const ModalDeliveryAddress = ({
  visible,
  onClose,
  fromParentLocation = '',
  fromLocation = '',
  fromMain = false,
  successCallback = () => {},
  baseAddress = null,
  isAnonymous = false,
}) => {
  const [address, setAddress] = useState(baseAddress || defaultAddress);
  const history = useHistory();

  useEffect(()=>{
    if (visible) {
      // Для анонимных юзеров
      if (isAnonymous) {
        // Сохраняем поля модалки и перезатераем полями базового адреса.
        // Если модалку закроют, то при открытии сможем показать детальные поля.
        setAddress((address) => ({ ...address, ...baseAddress, default: false }))
        return
      }

      // Для авторизованных получаем актуальных адрес
      getAddress(
        (res) => {
          if(res.street) {
            setAddress(res)
          }
        },
      )
    } else {
      // При закрытии модалки для анонимных юзеров ничего не обнуляем
      if (isAnonymous) {
        return
      }

      setAddress(defaultAddress)
    }
  }, [visible, isAnonymous, baseAddress])

  const onChange = useCallback((field) => (e) =>{
    setAddress((prev) => ({ ...prev, [field]: e.target.value }))
  }, [])

  const onSave = useCallback((e) => {
    e.preventDefault();
    // Редиректы после кнопки Применить в модалке

    // Для анонимных редирект на авторизацию с выбранным адресом
    if (isAnonymous) {
      history.push('/Login', { address })
      return
    }

    // Для авторизованных сохраняем адрес
    saveAddress(address, () => {
      // Если пришли с главной - редирект в Профиль
      if (fromMain) {
        history.replace('/Lk')
        return
      }

      // Если модалку открыли со страницы Редактирования профиля, то просто закрываем модалку и вызываем колбек
      if (fromLocation === 'LkRedact') {
        successCallback(address)
        return onClose()
      }

      // Если модалку открыли на странице Address, то нужно вернуться на 2 шага назад.
      // Если с карты, то на 1 шаг назад.
      const steps = fromLocation === 'Address' ? -2 : (
        fromLocation === 'Map' ? -1 : 0
      )

      // fromParentLocation - наша страница, с которой пришли на страницу Map / Map -> Address
      // Если она существует, то значит при возврате на 1-2 шага назад мы попадем на страницу нашего сайта,
      // а не на пустую вкладку или страницу чужого сайта.
      // Доп. убеждаемся, что в histоry stack есть хоть какие-то страницы.
      if (fromParentLocation && history.length && history.length + steps > 1) {
        history.go(steps)
        return
      }

      // В противном случае делаем редирект на страницу Профиль
      history.replace('/Lk')
    })
  }, [address, onClose, fromLocation, fromMain, fromParentLocation, history, isAnonymous])

  const renderAddress = useMemo(() => {
    if (!address.default && address.street) {
      return `${address.street}, ${address.house}`
    }

    return 'Адрес неизвестен'
  }, [address])

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title="Изменить адрес"
      mod="cart"
      onSubmit={false}
    >
      <div className="delivery-address">
        <form onSubmit={onSave} method="post">
          <div className="delivery-address__body">
            <div className="col-12">
              <Input
                placeholder="Адрес"
                value={renderAddress}
                label="Адрес*"
                id="delivery-address__address"
                disabled
              />
            </div>
            {addressInp.map((item) => (
              <div className={item.classNameCol} key={item.id}>
                <Input
                  onChange={onChange(item.name)}
                  value={address[item.name]}
                  placeholder={item.placeholder}
                  label={item.placeholder}
                  id={`delivery-address__${item.name}`}
                  isTextarea={item.isTextarea}
                />
              </div>
            ))}
          </div>
          <div className="heightContent-bottom heightContent-bottom--margin-top text-center">
            <Button variant="primary" type="submit">Применить</Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default ModalDeliveryAddress
