import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {Button} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {userInfoSelector} from "../../store/selectors/user";
import {setUserInfo} from "../../store/actions/user";
import {useReducer} from "../../util/store";
import API from "../../api";
import {defaultAddress, getAddress} from "../../query/address";
import ModalDeliveryAddress from "../../components/modal-delivery-address";
import AddressBtn from "../../components/address-btn";
import Input from "../../components/input/index";
import TopPage from '../../components/top-page';
import { INITIAL_SEC_RESEND_CODE } from "../../constants/code";
import useLayout from "../../util/layout"
import './lk-redact.scss'

const LkRedact = () => {
    useLayout()
    const dispatch = useDispatch()
    const [alert, setAlert] = useReducer('alert');
    const [confirm, setConfirm] = useState(false);
    const [isRepeat, toggleRepeat] = useState(false);
    const [resend, setResend] = useState(INITIAL_SEC_RESEND_CODE);
    const [fields, setFields] = useState({
        firstname: { value: '', error: '' },
        telephone: { value: '', error: '' },
        code: { value: '', error: '' },
    });

    const [address, setAddress] = useState(defaultAddress);
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        getAddress(setAddress)
    }, [])

    const userData = useSelector(userInfoSelector)

    useEffect(() => {
        setFields({
            firstname: { value: userData.firstname, error: '' },
            telephone: { value: userData.telephone, error: '' },
            code: { value: '', error: '' },
        })
    }, [userData.telephone, userData.firstname])

    // TODO: таймер пока не нужен
    // useEffect(() => {
    //     let timer = null
    //     if (isRepeat) {
    //         return () => clearInterval(timer)
    //     }

    //     timer = setInterval(() => {
    //         if (resend === 0) return
    //         setResend((prev) => {
    //             const state = prev - 1
    //             if (state === 0) {
    //                 toggleRepeat(true)
    //             }
    //             return state
    //         })
    //     }, 1000)

    //     return () => clearInterval(timer)
    // }, [isRepeat])


    const saveData = useCallback((e) => {
        e.preventDefault();
        const body = {
            firstname: fields.firstname.value.trim(),
            telephone: fields.telephone.value.trim(),
            code: fields.code.value.trim(),
        }

        API.post(
            '/user',
            body,
            function (response){
                if(response.code){
                    setAlert({danger: response.message})
                }else if(response.message){
                    setAlert({'info': response.message});
                    setConfirm(true);
                    setResend(INITIAL_SEC_RESEND_CODE)
                    toggleRepeat(false)
                }
                if(response.email || response.firstname){
                    if(body.code){
                        API.login(response.telephone, body.code);
                    }
                    setAlert({success: 'Сохранено'});
                    setConfirm(false);

                    const obj = { ...response }
                    if (body.code) {
                        obj.code = body.code
                    }

                    dispatch(setUserInfo(obj))
                }
            },
            () => {},
            { isHideAlert: true }
        );
    }, [fields])

    const resendCode = useCallback((e) => {
        const body = {
            telephone: fields.telephone.value.trim(),
        }

        setResend(INITIAL_SEC_RESEND_CODE)
        toggleRepeat(false)

        API.post(
            '/user',
            body,
            function (response){
                if(response.code){
                    setAlert({danger: response.message})
                }else if(response.message){
                    setAlert({'info': response.message});
                }
            }
        );
    }, [fields])

    const onChange = useMemo(() => ({
        firstname: (e) => setFields((prev) => ({
            ...prev, firstname: { value: e.target.value, error: e.target.value.length > 40 ? 'max-limit' : '' }
        })),
        telephone: (e) => setFields((prev) => ({ ...prev, telephone: { value: e.target.value, error: '' } })),
        code: (e) => setFields((prev) => ({ ...prev, code: { value: e.target.value, error: '' } })),
    }), [])

    const disabledBtn = useMemo(() => {
        if (userData.firstname === fields.firstname.value && userData.telephone === fields.telephone.value) {
            return true
        }

        if (fields.firstname.value.length === 0 || !!fields.firstname.error || fields.telephone.value.length === 0) {
            return true
        }

        if (confirm && fields.code.value.length === 0) {
            return true
        }

        return false
    }, [userData, fields, confirm])

    const renderAddress = useMemo(() => {
        if (!address.default && address.street) {
            const list = [address.street, address.house, address.apartment ? 'кв. ' + address.apartment : null].filter((v) => v)
            return list.join(', ')
        }

        return 'Адрес неизвестен'
    }, [address])

    const [modalDeliveryAddress, setModalDeliveryAddress] = useState(false)

    const onClickAddress = useCallback(() => history.push('/Map', { from: location.pathname }), [history, location.pathname])
    const onShowModalAddress = useCallback(() => setModalDeliveryAddress(true), [])
    const onHideModalAddress = useCallback(() => setModalDeliveryAddress(false), [])

    return (
        <div className="lk-redact layout">
            <div className="layout__top layout__top--gray">
                <TopPage
                    to="/Lk"
                    title="Профиль"
                />
            </div>
            <div className="layout__body">
                <div className="grid-inner">
                    <form onSubmit={saveData}>
                        <div className="lk-redact__block">
                            <Input
                                type="text"
                                name="firstname"
                                placeholder="Ваше имя"
                                label="Ваше имя"
                                onChange={onChange.firstname}
                                value={fields.firstname.value}
                                error={!!fields.firstname.error}
                                id="firstname"
                            />
                            <Input
                                name="telephone"
                                label="Ваш телефон"
                                mask="+7(\999)-999-99-99"
                                disabled
                                onChange={onChange.telephone}
                                placeholder="+7(___)-___-__-__"
                                value={fields.telephone.value}
                                id="phone"
                                isMask
                            />
                            {confirm ?
                                <Input
                                    name="code"
                                    label="Введите код"
                                    onChange={onChange.code}
                                    className="form-control"
                                    placeholder="Код"
                                    value={fields.code.value}
                                    id="code"
                                />
                            : null}
                            {confirm ? (
                                isRepeat ? (
                                    <Button variant="dark" type="button" onClick={resendCode}>Отправить новый код</Button>
                                ) : (
                                    <p className="lk-redact__text">Получить новый код можно через 00:{`${resend}`.padStart(2, '0')}</p>
                                )
                            ) : null}
                        </div>
                        <div className="lk-redact__block lk-redact__block--address">
                            <div className="lk-redact__address-title">Ваш адрес</div>
                            <AddressBtn
                                onClick={onClickAddress}
                                onClickDetails={onShowModalAddress}
                                text={renderAddress}
                            />
                        </div>
                        <Button variant="primary" type="submit" disabled={disabledBtn}>Сохранить</Button>
                    </form>
                </div>
            </div>
            <ModalDeliveryAddress
                fromLocation="LkRedact"
                visible={modalDeliveryAddress}
                onClose={onHideModalAddress}
                successCallback={setAddress}
            />
        </div>
    )
}

export default LkRedact
